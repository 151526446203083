const cfg = {
    defaultURL: 'http://176.109.104.212/api',
    apiURL: 'http://176.109.104.212/api',
    userURL: 'http://176.109.104.212/api',
    socketURL: 'http://176.109.104.212',

    // defaultURL: 'http://176.109.104.212',
    // apiURL: 'http://176.109.104.212/api',
    // userURL: 'http://176.109.104.212/api/user',
    // api: 'http://176.109.104.212/api',
    // socketURL: 'https://socket.luckyjetapi.cfd'
}
export default cfg;